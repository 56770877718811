import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import Button from '../Button';

const Header = () => (
  <header className="sticky top-0 bg-white shadow z-10">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl text-gray-800">
        <div className="w-12 mr-3">
          <LogoIcon />
        </div>
        SmartBO
      </div>
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4" href="#apps">
          Nuestras APPs
        </AnchorLink>
         <AnchorLink className="px-4" href="#features">
          Caracteristicas
        </AnchorLink>
        <AnchorLink className="px-4" href="#support">
          Soporte
        </AnchorLink>
        {/* 
        <AnchorLink className="px-4" href="#testimonials">
          Testimonials
        </AnchorLink> */}
      </div>
      <div className="hidden md:block">
        {/* <a href="https://wa.me/15551234567" className="text-white">Consultar el servicio</a> */}
        {/* <Button className="text-sm">
        <a href="https://wa.me/15551234567" className="text-white">CONSULTAR EL SERVICIO</a>
        </Button> */}
      </div>
    </div>
  </header>
);

export default Header;
